import styled from '@emotion/styled';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

import { InnerHtml, Typography } from '@innovamat/glimmer-components';

import { useRegionParameters } from '../../../../../../../contents/hooks/use-region-parameters';
import { EMPTY_CELL_VALUE } from '../../../../utils';
import { replaceMathmlLocalization } from '../../../../utils/mathml/replace-mathml-localization';

type StatementCellProps = {
  isCorrect?: boolean;
  answer?: string;
};

const StatementCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledText = styled(Typography.Subtitle2)<{ hasNoAnswer: boolean }>`
  color: ${({ hasNoAnswer, theme }) =>
    hasNoAnswer
      ? theme.tokens.color.specific.reports.semantic['no-data'].value
      : 'inherit'};
`;

const MathMLExpression = styled(InnerHtml)`
  font-size: 0.775rem;
`;

const BottomLine = styled.div<{ hasNoAnswer: boolean; isCorrect?: boolean }>`
  margin-top: 2px;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: ${({ hasNoAnswer, isCorrect, theme }) => {
    if (hasNoAnswer) {
      return theme.tokens.color.specific.reports['progress-bar'].empty.value;
    }
    return isCorrect
      ? theme.tokens.color.specific.reports.semantic.high.value
      : theme.tokens.color.specific.reports.semantic.low.value;
  }};
`;

const isMathML = (answer: string): boolean => answer?.includes('</math>');

export function StatementCell({
  isCorrect,
  answer,
}: StatementCellProps): JSX.Element {
  const hasNoAnswer = !answer;
  const { regionParameters } = useRegionParameters();

  return (
    <StatementCellContainer>
      <StyledText hasNoAnswer={hasNoAnswer}>
        {isMathML(answer || '') ? (
          <MathJaxContext
            config={{
              options: {
                enableMenu: false,
              },
            }}
          >
            <MathJax>
              <MathMLExpression
                text={replaceMathmlLocalization(answer, regionParameters)}
              />
            </MathJax>
          </MathJaxContext>
        ) : (
          answer?.toLocaleLowerCase() || EMPTY_CELL_VALUE
        )}
      </StyledText>
      <BottomLine hasNoAnswer={hasNoAnswer} isCorrect={isCorrect} />
    </StatementCellContainer>
  );
}
