import styled from '@emotion/styled';

import { useRegionalNumber } from '@innovamat/ga-features';
import { Typography } from '@innovamat/glimmer-components';
import { getColorToken } from '../../../../utils';

type SemanticColorToken = 'super-high' | 'high' | 'medium' | 'low' | 'no-data';

type Props = {
  score?: number | null;
};

const Container = styled.div<{ colorToken: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, colorToken }) =>
    theme.tokens.color.specific.reports.semantic[
      colorToken as SemanticColorToken
    ].value};
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-inverted'].value};
  border-radius: 4px;
  width: 64px;
  height: 32px;
`;

function ScoreCell({ score }: Props): JSX.Element {
  const colorToken = getColorToken(score);
  const regionalNumber = useRegionalNumber({ number: score ?? 0 });

  return (
    <Container colorToken={colorToken}>
      <Typography.Subtitle1>{regionalNumber}</Typography.Subtitle1>
    </Container>
  );
}

export { ScoreCell };
