import { manageError } from './unityEventsHandler';
import { store } from '../../store/store';
import { BuildData } from './types';
import { schoolsWithFirewall } from '@innovamat/unity-toolkit-errors';

const biomeToCityMapper = (biomeId: number) => {
  switch (biomeId) {
    case 2:
      return 'District_Ice';
    case 1:
    default:
      return 'District_Central';
  }
};

export const getCurrentCity = () => {
  const {
    uData: { biomeId },
  } = store.getState();
  return biomeToCityMapper(biomeId);
};

const UnityBuildDomain = (): string => {
  const {
    auth: {
      user: { school },
    },
  } = store.getState();
  return schoolsWithFirewall.includes(school || '')
    ? 'https://d1ecifsh34r90d.cloudfront.net'
    : (process.env.NX_UNITY_BUILD_DOMAIN as string);
};

export const BUILD_NOT_FOUND = 'BUILD_NOT_FOUND';

const stagePath = 'Primary';

export const getProperHomePerSelectedStage = (
  selectedStage: string
): string => {
  return selectedStage === 'earlyYears' ? 'HomeInfantil' : 'HomeGreen';
};

export const getParentSceneByChildStage = (
  Students: any,
  User: any
): string => {
  if (Students?.length || User) {
    const selectedUser = User || Students?.[0].user;
    const course =
      selectedUser.classroom?.course || selectedUser.educationalPlan?.course;
    return course.intValue <= 5 ? 'ScrollInfantil' : getCurrentCity();
  }
  return getCurrentCity();
};

const sceneToBuildMapper = (
  sceneName: string,
  selectedStage: string
): string => {
  // isScene
  switch (sceneName) {
    // Scenes to Home
    case 'AppStartLoadingInnovamat':
      return getProperHomePerSelectedStage(selectedStage);
    // Scenes to City
    case 'CityB2B':
    case 'B2BCityShop':
    case 'CityTransitionPanelB2B':
    case 'CityRoot':
    case 'WorldMap':
    case 'CityInterface':
    case 'CityDevTool':
      return 'District_Central';
    // Scenes to ScrollInfantil
    case 'B2BUIAppletSelectorLoader':
      return 'ScrollInfantil';
    // Scenes to TrainingZone
    case 'training_zone':
      return 'TrainingZone';
    // Scenes as Build
    case 'HomeInfantil':
    case 'HomeGreen':
    case 'District_Central':
    case 'District_Ice':
    case 'ActivityManager':
    case 'ScrollInfantil':
    case 'FairHome':
    case 'F1':
    case 'F2':
    case 'F3':
    case 'F4':
    default:
      return sceneName;
  }
};

const appletBuildPath = async (sceneName: string): Promise<string> => {
  const buildsUrl = `${UnityBuildDomain()}/${stagePath}/BuildRoutes/routes.json`;
  const response = await fetch(buildsUrl);
  const builds = await response.json();
  const routedBuildPath = builds[sceneName];
  if (!routedBuildPath) {
    return BUILD_NOT_FOUND;
  }
  return `${stagePath}${routedBuildPath}`;
};

const webappBuildPath = async (
  sceneName: string,
  selectedStage: string
): Promise<string> => {
  const resolvedSceneName = sceneToBuildMapper(sceneName, selectedStage);

  const buildsUrl = `${UnityBuildDomain()}/WebApp/BuildRoutes/routes.json`;
  const response = await fetch(buildsUrl);
  const builds = await response.json();
  const routedBuildPath = builds[resolvedSceneName];

  if (!routedBuildPath) {
    return BUILD_NOT_FOUND;
  }
  return `WebApp${routedBuildPath}`;
};

export const isApplet = (sceneName?: string): boolean => {
  if (!sceneName) return false;
  switch (sceneName) {
    case 'HomeInfantil':
    case 'HomeGreen':
    case 'District_Central':
    case 'District_Ice':
    case 'ActivityManager':
    case 'ScrollInfantil':
    case 'F1':
    case 'F2':
    case 'F3':
    case 'F4':
    case 'AppStartLoadingInnovamat':
    case 'FairHome':
    case 'CityB2B':
    case 'B2BCityShop':
    case 'CityTransitionPanelB2B':
    case 'CityRoot':
    case 'WorldMap':
    case 'CityInterface':
    case 'CityDevTool':
    case 'B2BUIAppletSelectorLoader':
    case 'ErrorScene_Innovamat':
    case 'TrainingZone':
    case 'FluencyZone':
      return false;
    // Applet case
    default:
      return true;
  }
};

export const buildResolver = async (
  sceneName: string,
  selectedStage: string
): Promise<BuildData> => {
  const buildPath = isApplet(sceneName)
    ? await appletBuildPath(sceneName)
    : await webappBuildPath(sceneName, selectedStage);

  if (buildPath === BUILD_NOT_FOUND) {
    const message = `Exception: ${BUILD_NOT_FOUND} trying to resolve build for scene: ${sceneName} and stage: ${selectedStage}`;
    manageError(message);
    return {
      buildPath: BUILD_NOT_FOUND,
    };
  }
  const buildData = {
    buildPath: buildPath.replace('Build/index.html', ''),
    buildDomain: UnityBuildDomain(),
  };
  return buildData;
};
