import styled from '@emotion/styled';
import {
  Button,
  Drawer,
  Tabs,
  Typography,
} from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { useMockExams } from './context/mock-exams-provider';

import {
  ERROR_TYPE,
  ErrorPage,
  useCurrentNavigationValues,
} from '@innovamat/ga-features';
import { EmptyReport } from '@innovamat/glimmer-assets';
import { EmptyState } from '@innovamat/glimmer-components';
import { useClassroomTestRoundDetailQuery } from '@innovamat/glow-api-client';

import { TimeFormatter } from '../../../../components/time-fomatter/time-formatter';
import { formatDate } from '../../utils/date';

import { useMemo, useState } from 'react';
import { InfoTag } from './components/info-tag';
import { MockExamsDistributionChart } from './components/mock-exams-distribution-chart';
import type { MockExamsStudentRow } from './components/mock-exams-drawer-table';
import { MockExamsDrawerTable } from './components/mock-exams-drawer-table';
import { MockExamsDrawerHeader } from './mock-exams-drawer-header';
import { MockExamsDrawerSkeleton } from './mock-exams-drawer-skeleton';

type MockExamsDrawer = {
  id: string | undefined;
  onCloseDrawer: () => void;
  isNextDisabled: boolean;
  isPreviousDisabled: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
};

const TABS_ID = {
  STUDENTS: 'students',
  DISTRIBUTION: 'distribution',
  QUESTIONS: 'questions',
};

const Container = styled.div`
  padding: 16px 32px;
  width: 1100px;
  max-width: 100vw;
  overflow-x: hidden;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 600px;
`;

export const InfoTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const Description = styled(Typography.Body2)`
  margin-top: 8px;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

export const TabsContent = styled.div``;

export const TabsContainer = styled.div`
  margin-bottom: 32px;
  border-bottom: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
`;

export function MockExamsDrawer(): JSX.Element {
  const { t } = useTranslation();
  const { onCloseDrawer, isDrawerOpen } = useMockExams();
  const { currentClassroom } = useCurrentNavigationValues();

  const { data, isLoading, isError } = useClassroomTestRoundDetailQuery(
    {
      testRoundId: isDrawerOpen ?? '',
      classroomId: currentClassroom?.id ?? '',
    },
    { enabled: !!isDrawerOpen, staleTime: Infinity }
  );

  const detailData = data?.classroomTestRoundDetail;
  const chartData = useMemo(() => {
    return (
      detailData?.students?.map((student) => ({
        name: student?.firstName
          ? `${student.firstName} ${student.lastName}`
          : t('common.unknown'),
        score: student?.score ?? 0,
      })) ?? []
    );
  }, [detailData, t]);

  const [selectedTab, setSelectedTab] = useState(TABS_ID.STUDENTS);

  const MOCK_EXAMS_TABS = [
    {
      selected: selectedTab === TABS_ID.STUDENTS,
      text: t(`mock-exams-drawer.tabs.${TABS_ID.STUDENTS}`),
      onClick: () => setSelectedTab(TABS_ID.STUDENTS),
    },
    {
      selected: selectedTab === TABS_ID.DISTRIBUTION,
      text: t(`mock-exams-drawer.tabs.${TABS_ID.DISTRIBUTION}`),
      onClick: () => setSelectedTab(TABS_ID.DISTRIBUTION),
    },
    // {
    //   selected: selectedTab === TABS_ID.QUESTIONS,
    //   text: 'mock-exams-drawer.tabs.${TABS_ID.QUESTIONS}',
    //   onClick: () => setSelectedTab(TABS_ID.QUESTIONS),
    // },
  ];

  return (
    <Drawer
      key="mock-exams-drawer"
      open={Boolean(isDrawerOpen)}
      onClose={onCloseDrawer}
      roundedBorder
    >
      <Container>
        <MockExamsDrawerHeader />
        {isLoading ? (
          <MockExamsDrawerSkeleton />
        ) : isError ? (
          <ErrorPage errorType={ERROR_TYPE.NOT_AVAILABLE} />
        ) : (
          <ContentContainer>
            <FlexContainer>
              <InfoContainer>
                <Description>{t(`${detailData?.description}`)}</Description>
                <InfoTagsContainer>
                  <InfoTag
                    text={`${formatDate(
                      detailData?.startDate ?? ''
                    )} - ${formatDate(detailData?.endDate ?? '')}`}
                  />
                  <InfoTag
                    title={t('mock-exams-drawer.info-tag.duration')}
                    text={
                      <TimeFormatter
                        timeInSeconds={Number(
                          detailData?.duration?.toString() ?? ''
                        )}
                        format={t('digitalguides.se.duration', {
                          duration: '{m}',
                        })}
                      />
                    }
                  />
                  <InfoTag
                    title={t('mock-exams-drawer.info-tag.participation')}
                    text={`${detailData?.participation?.completed} / ${detailData?.participation?.total}`}
                  />
                </InfoTagsContainer>
              </InfoContainer>

              <Button
                variant="secondary"
                leftIcon="DownloadIcon"
                rightIcon="ExpandMoreIcon"
                oneLine
                disabled
              >
                {t('mock-exams-drawer.generate-button')}
              </Button>
            </FlexContainer>

            <TabsContent>
              <TabsContainer>
                <Tabs tabs={MOCK_EXAMS_TABS} />
              </TabsContainer>

              {selectedTab === TABS_ID.STUDENTS && (
                <>
                  {detailData?.students?.length === 0 ? (
                    <EmptyState
                      Image={EmptyReport}
                      title={t('mock-exams-drawer.emptyState.title')}
                      subtitle={t('mock-exams-drawer.emptyState.subtitle')}
                    />
                  ) : (
                    <MockExamsDrawerTable
                      students={
                        (detailData?.students as MockExamsStudentRow[]) ?? []
                      }
                      numStatements={detailData?.numberOfStatements ?? 0}
                    />
                  )}
                </>
              )}

              {selectedTab === TABS_ID.DISTRIBUTION && (
                <MockExamsDistributionChart data={chartData} />
              )}
            </TabsContent>
          </ContentContainer>
        )}
      </Container>
    </Drawer>
  );
}
